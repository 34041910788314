import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setService } from '../store/ui/dashboard';
import { setAccountID } from '../store/middleware/api';

export function useAccountFromPath() {
  const params = useParams();
  const dispatch = useDispatch();
  let accountId = params['*'].split('/')[0];
  if (accountId === 'settings') {
    accountId = null;
  } else if (accountId !== '') {
    if (
      !RegExp('[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}').test(
        accountId,
      )
    ) {
      document.location = '/';
    }
  }

  setAccountID(accountId);

  useEffect(() => {
    setAccountID(accountId);
  }, [dispatch, accountId]);
}

export function useServiceFromPath() {
  const params = useParams();
  let accountId = null;
  let serviceId = null;
  if (params['*']) {
    accountId = params['*'].split('/')[0];
    serviceId = params['*'].split('/')[2];
  }
  const dispatch = useDispatch();

  useEffect(() => {
    if (accountId) {
      setAccountID(accountId);
    }
    if (serviceId) {
      dispatch(setService(serviceId));
    }
  }, [dispatch, serviceId, accountId]);

  return serviceId;
}

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('div');

  if (!listContainer) {
    listContainer = document.createElement('div');
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);
    ul.className = 'legend-container';
    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement('div');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.marginLeft = '10px';
      li.style.listStyle = 'none';
      li.style.display = 'flex';
      li.style.gap = '10px';

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = 'none';
      boxSpan.style.border = '4px solid ' + item.strokeStyle;
      boxSpan.style.borderRadius = '7px';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '12px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '22px';

      // Text
      const textContainer = document.createElement('div');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

export const behavior_action_types = {
  CACHE_BEHAVIOR: 'Cache Behavior',
  CACHE_TTL: 'Cache TTL',
  REDIRECT_HTTP_TO_HTTPS: 'Redirect HTTP to HTTPS',
  SET_RESPONSE_HEADER: 'Set Response Header',
  BROWSER_CACHE_TTL: 'Browser Cache TTL',
  REDIRECT: 'Redirect to URL',
  ORIGIN_CACHE_CONTROL: 'Origin Cache Control',
  DISABLE_WAF: 'Disable WAF',
  BYPASS_CACHE_ON_COOKIE: 'Bypass Cache On Cookie',
  CACHE_KEY: 'Cache Key',
  AUTO_MINIFY: 'Auto Minify',
  HOST_HEADER_OVERRIDE: 'Host Header Override',
  SET_CORS_HEADER: 'Set CORS Header',
  OVERRIDE_ORIGIN: 'Origin Override',
  ORIGIN_ERRORS_PASS_THRU: 'Origin Errors Pass-Thru',
  FOLLOW_REDIRECTS: 'Follow Redirects',
  STATUS_CODE_CACHE: 'Status Code Cache',
  GENERATE_PREFLIGHT_RESPONSE: 'Generate Preflight Response',
  STATUS_CODE_BROWSER_CACHE: 'Status Code Browser Cache',
  STALE_TTL: 'Stale TTL',
  STREAM_LOGS: 'Stream Logs',
  ALLOWED_METHODS: 'Allowed Methods',
  COMPRESSION: 'Compression',
  GENERATE_RESPONSE: 'Generate Response',
  CACHED_METHODS: 'Cached Methods',
  SET_REQUEST_HEADER: 'Set Request Header',
  VIEWER_PROTOCOL: 'Viewer Protocol',
  DELETE_REQUEST_HEADER: 'Delete Request Header',
  DELETE_RESPONSE_HEADER: 'Delete Response Header',
  LARGE_FILES_OPTIMIZATION: 'Large Files Optimization',
  URL_SIGNING: 'URL Signing',
};

export const wafFields = [
  {
    value: 'REQUEST_HEADERS:User-Agent',
    label: 'User Agent',
  },
  {
    value: 'REQUEST_METHOD',
    label: 'Request Method',
  },
  {
    value: 'REQUEST_URI_RAW',
    label: 'URL',
  },
  {
    value: 'PATH_INFO',
    label: 'URL Path',
  },
  {
    value: 'ARGS_GET',
    label: 'URL Query',
  },
  {
    value: 'ARGS_GET:',
    label: 'Query Parameter Value',
  },
  {
    value: 'ARGS_GET_NAMES',
    label: 'Query Parameter Names',
  },
  {
    value: 'REQUEST_HEADERS:Referer',
    label: 'Referer',
  },
  {
    value: 'REQUEST_HEADERS:Host',
    label: 'Host',
  },
  {
    value: 'REQUEST_HEADERS:X-IORIVER-ASN',
    label: 'ASN',
  },
  {
    value: 'REQUEST_HEADERS:X-IORIVER-COUNTRY',
    label: 'Country',
  },
  {
    value: 'REMOTE_ADDR',
    label: 'Client IP',
  },
  {
    value: 'REQUEST_BODY',
    label: 'Raw Request Body',
  },
  {
    value: 'ARGS_POST',
    label: 'JSON Request Body',
  },
  {
    value: 'REQUEST_COOKIES',
    label: 'Cookie',
  },
];

export const providerIcons = {
  cloudflare: '/cloudflare_logo.png',
  cloudfront: '/cloudfront_logo.png',
  fastly: '/fastly_logo.png',
  akamai: '/akamai_logo.png',
  'azure cdn': '/azure_cdn_logo.png',
  lumen: '/lumen_logo.png',
  stackpath: '/stackpath_logo.png',
  edgenext: '/edgenext_logo.png',
  gcore: '/gcore_logo.png',
  cachefly: '/cachefly_logo.png',
  other: '/other_logo.png',
  limelight: '/limelight_logo.png',
  edgio: '/edgio_logo.png',
  cdnetworks: '/cdnetworks_logo.png',
  gcpcloudcdn: '/gcp_cloud_cdn_logo.png',
  gcpmediacdn: '/gcp_media_cdn_logo.png',
  mainstreaming: '/mainstreaming_logo.png',
  bunny: '/bunny_logo.png',
};

export function getProviderIcon(serviceProvider) {
  if (!serviceProvider) {
    return '';
  }

  if (serviceProvider.is_vcdn) {
    return '/vcdn_logo.png';
  }
  return providerIcons[serviceProvider.name.toLowerCase()];
}

export function translateProviderName(name, displayName) {
  switch (name.toLowerCase()) {
    case 'cloudfront':
      return 'CloudFront';
    case 'other':
      return displayName;
    default:
      return name;
  }
}

export function getProviderDisplayName(serviceProvider) {
  if (serviceProvider) {
    if ('display_name' in serviceProvider && serviceProvider.display_name) {
      return serviceProvider.display_name;
    }

    let name = translateProviderName(serviceProvider.name);
    if (serviceProvider.is_vcdn) {
      name = 'vCDN';
    }
    return name;
  }

  return '';
}

export function getProviderAbbreviation(serviceProvider) {
  if (!serviceProvider) {
    return '';
  }

  return providerAbbreviationFromName(serviceProvider.name);
}

export function providerAbbreviationFromName(name) {
  switch (name.toLowerCase()) {
    case 'fastly':
      return 'fs';
    case 'cloudflare':
      return 'cf';
    case 'cloudfront':
      return 'cfrnt';
    case 'azure cdn':
      return 'azcdn';
    case 'akamai':
      return 'akamai';
    case 'edgio':
      return 'edgio';
    case 'gcpcloudcdn':
      return 'gcpcloudcdn';
    case 'gcpmediacdn':
      return 'gcpmediacdn';
    case 'cdnetworks':
      return 'cdnetworks';
    case 'gcore':
      return 'gcore';
    case 'mainstreaming':
      return 'mainstreaming';
    case 'bunny':
      return 'bunny';
    case 'other':
      return 'other';
    default:
      return name;
  }
}

export function abbreviationToDisplayName(abbr) {
  switch (abbr.toLowerCase()) {
    case 'fs':
      return 'Fastly';
    case 'cf':
      return 'Cloudflare';
    case 'cfrnt':
      return 'CloudFront';
    case 'azcdn':
      return 'Azure CDN';
    case 'akamai':
      return 'Akamai';
    case 'gcpcloudcdn':
      return 'GCP Cloud CDN';
    case 'gcpmediacdn':
      return 'GCP Media CDN';
    case 'edgio':
      return 'Edgio';
    case 'mainstreaming':
      return 'MainStreaming';
    default:
      return abbr;
  }
}

export function abbreviationToProviderName(abbr) {
  switch (abbr.toLowerCase()) {
    case 'fs':
      return 'Fastly';
    case 'cf':
      return 'Cloudflare';
    case 'cfrnt':
      return 'CloudFront';
    case 'azcdn':
      return 'Azure CDN';
    case 'akamai':
      return 'Akamai';
    case 'gcpcloudcdn':
      return 'GCPCloudCDN';
    case 'gcpmediacdn':
      return 'GCPMediaCDN';
    case 'edgio':
      return 'Edgio';
    case 'mainstreaming':
      return 'MainStreaming';
    default:
      return abbr;
  }
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function truncateMonth(inputDate) {
  // Create a new date based on the input date
  const firstDayOfMonth = new Date(inputDate);

  // Set the date to the first day of the month
  firstDayOfMonth.setUTCDate(1);

  // Set the time to midnight
  firstDayOfMonth.setUTCHours(0, 0, 0, 0);

  return firstDayOfMonth;
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

/**
 * Parse the input number with minimum fraction digits.
 * @param {*} inp an input
 * @param {*} num minimum fraction
 * @returns a string representation of the input
 */
export const withMinFraction = (inp, num = 1) => {
  return inp.toLocaleString(undefined, {
    minimumFractionDigits: num,
  });
};
