import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import JSONbigint from 'json-bigint';

type TrafficPolicyType = 'Static' | 'Dynamic' | 'Cost based';

interface Provider {
  service_provider: string;
  weight: number;
  priority: number | null;
  is_commitment_priority: boolean | null;
}

interface Geo {
  continent: string | null;
  country: string | null;
  subdivision: string | null;
}

interface TrafficPolicy {
  id: string;
  type: TrafficPolicyType;
  service: string;
  failover: boolean;
  is_default: boolean;
  performance_penalty: number | null;
  providers: Provider[];
  geos: Geo[];
  health_checks: { health_check: string }[];
  performance_checks: { performance_check: string }[];
  enable_performance_penalty: boolean | null;
}

interface UpdatingState {
  step: number;
  wizardStateVersion: number;
  trafficPolicy: TrafficPolicy;
  openAccordionSection: number;
  allowNext?: boolean;
}

interface PerformanceCheck {
  id: string;
  service: string;
  enabled: boolean;
  url: string;
  name: string;
}

export interface HealthCheck {
  id: string;
  service: string;
  enabled: boolean;
  url: string;
  name: string;
}

interface TrafficPoliciesState {
  list: TrafficPolicy[];
  performanceChecks: PerformanceCheck[];
  healthChecks: HealthCheck[];
  loading: boolean;
  lastFetch: number | null;
  updating: UpdatingState | null;
  waitingForModifyResponse: boolean;
  error: string | null;
  editMonitorVisible: boolean;
  newMonitorVisible: boolean;
  updatingMonitor: null;
}

function recalculateStep(trafficPolicies: TrafficPoliciesState, newState: UpdatingState) {
  if (newState.step === 1) {
    newState.allowNext = newState.trafficPolicy.providers.length > 0;
  } else if (newState.step === 2) {
    if (newState.trafficPolicy.providers.length === 1) {
      newState.step++;
      newState.trafficPolicy.type = 'Static';
    }
    newState.allowNext = Boolean(newState.trafficPolicy.type);
  } else if (newState.step === 3) {
    let allowNext = true;
    if (newState.trafficPolicy.geos.length === 0) {
      allowNext = false;
    }

    if (
      newState.trafficPolicy.type === 'Dynamic' &&
      newState.trafficPolicy.performance_checks.length === 0
    ) {
      allowNext = false;
    }

    if (newState.trafficPolicy.type === 'Static' && newState.trafficPolicy.providers.length > 1) {
      let sum = 0;
      newState.trafficPolicy.providers.map((p) => {
        sum += p.weight;
        return null;
      });

      if (sum !== 100) {
        allowNext = false;
      }
    }

    if (newState.trafficPolicy.failover === null) {
      allowNext = false;
    } else if (
      newState.trafficPolicy.failover &&
      newState.trafficPolicy.health_checks.length === 0
    ) {
      allowNext = false;
    }

    newState.allowNext = allowNext;
  }
  trafficPolicies.updating = newState;
}

function sortPolicies(list: TrafficPolicy[]) {
  list.sort((a, b) => {
    if (a.is_default) {
      return 1;
    }
    if (b.is_default) {
      return -1;
    }
    return 0;
  });
}

const initialState: TrafficPoliciesState = {
  list: [],
  performanceChecks: [],
  healthChecks: [],
  loading: false,
  lastFetch: null,
  updating: null,
  waitingForModifyResponse: false,
  error: null,
  editMonitorVisible: false,
  newMonitorVisible: false,
  updatingMonitor: null,
};

const slice = createSlice({
  name: 'trafficPolicies',
  initialState,
  reducers: {
    // actions => action handlers

    trafficPoliciesReceived: (trafficPolicies, action) => {
      trafficPolicies.list = action.payload[0];
      sortPolicies(trafficPolicies.list);
      trafficPolicies.loading = false;
      trafficPolicies.lastFetch = Date.now();
    },

    trafficPolicyAdded: (trafficPolicies, action) => {
      trafficPolicies.list.push(action.payload[0]);
      sortPolicies(trafficPolicies.list);
      trafficPolicies.updating = null;
      trafficPolicies.waitingForModifyResponse = false;
      trafficPolicies.error = null;
    },

    trafficPolicyUpdated: (trafficPolicies, action) => {
      const index = trafficPolicies.list.findIndex(
        (behavior) => behavior.id === action.payload[0].id,
      );
      trafficPolicies.list[index] = action.payload[0];
      trafficPolicies.updating = null;
      trafficPolicies.waitingForModifyResponse = false;
      trafficPolicies.error = null;
    },

    waitingForResponse: (trafficPolicies, action) => {
      trafficPolicies.waitingForModifyResponse = true;
    },

    trafficPolicyDeleted: (trafficPolicies, action) => {
      const deleted = action.payload[1].payload.id;
      trafficPolicies.list = trafficPolicies.list.filter((p) => p.id !== deleted);
    },

    updateUpdatingState: (trafficPolicies, action) => {
      if (action.payload === null) {
        trafficPolicies.updating = null;
        window.location.hash = '';
        return;
      }
      trafficPolicies.error = null;
      let newState = JSONbigint.parse(JSON.stringify(action.payload));
      recalculateStep(trafficPolicies, newState);
      window.location.hash = trafficPolicies.updating?.trafficPolicy?.id || '';
    },

    performanceChecksReceived: (trafficPolicies, action) => {
      trafficPolicies.performanceChecks = action.payload[0];
      trafficPolicies.loading = false;
      trafficPolicies.lastFetch = Date.now();
    },

    performanceChecksAdded: (trafficPolicies, action) => {
      trafficPolicies.performanceChecks.push(action.payload[0]);
      if (trafficPolicies.updating) {
        trafficPolicies.updating.trafficPolicy.performance_checks = [
          { performance_check: action.payload[0].id },
        ];
        let newState = JSONbigint.parse(JSON.stringify(trafficPolicies.updating));

        recalculateStep(trafficPolicies, newState);
      }
      trafficPolicies.newMonitorVisible = false;
    },

    performanceCheckUpdated: (trafficPolicies, action) => {
      const index = trafficPolicies.performanceChecks.findIndex(
        (check) => check.id === action.payload[0].id,
      );
      trafficPolicies.performanceChecks[index] = action.payload[0];
      trafficPolicies.editMonitorVisible = false;
      trafficPolicies.updatingMonitor = null;
    },

    performanceCheckDeleted: (trafficPolicies, action) => {
      const deleted = action.payload[1].payload.id;
      trafficPolicies.performanceChecks = trafficPolicies.performanceChecks.filter(
        (p) => p.id !== deleted,
      );
    },

    healthChecksReceived: (trafficPolicies, action) => {
      trafficPolicies.healthChecks = action.payload[0];
      trafficPolicies.loading = false;
      trafficPolicies.lastFetch = Date.now();
    },
    healthChecksAdded: (trafficPolicies, action) => {
      trafficPolicies.healthChecks.push(action.payload[0]);
      if (trafficPolicies.updating) {
        trafficPolicies.updating.trafficPolicy.health_checks = [
          { health_check: action.payload[0].id },
        ];
        let newState = JSONbigint.parse(JSON.stringify(trafficPolicies.updating));
        recalculateStep(trafficPolicies, newState);
      }
      trafficPolicies.newMonitorVisible = false;
    },

    healthCheckUpdated: (trafficPolicies, action) => {
      const index = trafficPolicies.healthChecks.findIndex(
        (check) => check.id === action.payload[0].id,
      );
      trafficPolicies.healthChecks[index] = action.payload[0];
      trafficPolicies.editMonitorVisible = false;
      trafficPolicies.updatingMonitor = null;
    },

    healthCheckDeleted: (trafficPolicies, action) => {
      const deleted = action.payload[1].payload.id;
      trafficPolicies.healthChecks = trafficPolicies.healthChecks.filter((p) => p.id !== deleted);
    },

    errorOccurred: (trafficPolicies, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }

      trafficPolicies.error = msg;
      trafficPolicies.waitingForModifyResponse = false;
    },

    showEditMonitor: (trafficPolicies, action) => {
      trafficPolicies.editMonitorVisible = action.payload;
      if (!trafficPolicies.editMonitorVisible) {
        trafficPolicies.updatingMonitor = null;
      }
    },

    showNewMonitor: (trafficPolicies, action) => {
      trafficPolicies.newMonitorVisible = action.payload;
    },
    updateEditingMonitor: (trafficPolicies, action) => {
      trafficPolicies.updatingMonitor = action.payload;
      trafficPolicies.editMonitorVisible = !!action.payload;
    },
  },
});

export const {
  trafficPoliciesReceived,
  trafficPolicyAdded,
  trafficPolicyDeleted,
  updateUpdatingState,
  performanceChecksReceived,
  healthChecksReceived,
  performanceChecksAdded,
  healthChecksAdded,
  trafficPolicyUpdated,
  performanceCheckDeleted,
  healthCheckDeleted,
  performanceCheckUpdated,
  healthCheckUpdated,
  errorOccurred,
  waitingForResponse,
  showNewMonitor,
  showEditMonitor,
  updateEditingMonitor,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadTrafficPolicies = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/traffic-policies/`,
    onSuccess: trafficPoliciesReceived.type,
  });

export const addTrafficPolicy = (s: string, t: TrafficPolicy) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/traffic-policies/`,
    method: 'post',
    data: t,
    onSuccess: trafficPolicyAdded.type,
    onError: errorOccurred.type,
  });

export const updateTrafficPolicy = (s: string, id: string, t: TrafficPolicy) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/traffic-policies/${id}/`,
    method: 'put',
    data: t,
    onSuccess: trafficPolicyUpdated.type,
    onError: errorOccurred.type,
  });

export const deleteTrafficPolicy = (s: string, o: string) =>
  apiCallBegan({
    id: o,
    url: `/api/v1/services/${s}/traffic-policies/${o}/`,
    method: 'delete',
    onSuccess: trafficPolicyDeleted.type,
  });

export const loadPerformanceChecks = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/performance-checks/`,
    onSuccess: performanceChecksReceived.type,
  });

export const addPerformanceCheck = (s: string, p: PerformanceCheck) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/performance-checks/`,
    method: 'post',
    data: p,
    onSuccess: performanceChecksAdded.type,
    onError: errorOccurred.type,
  });

export const updatePerformanceCheck = (s: string, id: string, p: PerformanceCheck) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/performance-checks/${id}/`,
    method: 'put',
    data: p,
    onSuccess: performanceCheckUpdated.type,
    onError: errorOccurred.type,
  });

export const deletePerformanceCheck = (s: string, id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/performance-checks/${id}/`,
    method: 'delete',
    onSuccess: performanceCheckDeleted.type,
  });

export const addHealthCheck = (s: string, h: HealthCheck) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/health-checks/`,
    method: 'post',
    data: h,
    onSuccess: healthChecksAdded.type,
    onError: errorOccurred.type,
  });

export const updateHealthCheck = (s: string, id: string, h: HealthCheck) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/health-checks/${id}/`,
    method: 'put',
    data: h,
    onSuccess: healthCheckUpdated.type,
    onError: errorOccurred.type,
  });

export const deleteHealthCheck = (s: string, id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/health-checks/${id}/`,
    method: 'delete',
    onSuccess: healthCheckDeleted.type,
  });

export const loadHealthChecks = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/health-checks/`,
    onSuccess: healthChecksReceived.type,
  });
