import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { toast } from 'react-toastify';
import { setAccountID } from './middleware/api';

export type TipType =
  | 'CREATE_CERTIFICATE'
  | 'CERTIFICATE_DOMAIN_VERIFICATION'
  | 'CREATE_SERVICE'
  | 'ADD_CDN_PROVIDERS'
  | 'DEFINE_TRAFFIC_POLICY'
  | 'MOVE_TRAFFIC';

export interface Account {
  sso_enabled: boolean;
  acs_url: string;
  sso_entity_id: string;
  force_sso: boolean;
  on_boarding_active: boolean;
  tip_type: TipType;
  on_boarding_service_id: string | null;
}

interface UserAccount {
  name: string;
  id: string;
  role: string;
  services_count: number;
  show_billing: boolean;
}

interface User {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  job_title: string;
  mfa_enabled: boolean;
  accounts: UserAccount[];
}

export interface AuthState {
  account: Account | {};
  user: User | null;
  error: string | null;
  showSettings: boolean;
  editingUser: User | null;
  qrBase64: string | null;
  mfaEnabled: boolean;
  showMFALogin: boolean;
  ssoEnabled: boolean;
  forceSSO: boolean;
  requestAccountSent: boolean;
  registered: boolean | null;
}

const initialState: AuthState = {
  account: {},
  user: null,
  error: null,
  showSettings: false,
  editingUser: null,
  qrBase64: null,
  mfaEnabled: false,
  showMFALogin: false,
  ssoEnabled: false,
  forceSSO: false,
  requestAccountSent: false,
  registered: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (auth, action) => {
      if (action.payload[0]['mfa_required']) {
        auth.showMFALogin = true;
        return;
      }
      let savedURL = localStorage.getItem('savedURL');
      if (savedURL) {
        localStorage.removeItem('savedURL');
        window.location.href = savedURL;
        return;
      }
      window.location.href = '/';
    },

    loginFailed: (auth, action) => {
      notifyError(auth, 'Login failed', action);
    },

    registerSuccess: (auth, action) => {
      toast.success('Account successfully registered!');
      auth.registered = true;
    },

    registerFailed: (auth, action) => {
      notifyError(auth, 'Registration failed', action);
    },

    requestAccountSuccess: (auth, action) => {
      auth.requestAccountSent = true;
    },

    requestAccountFailed: (auth, action) => {
      notifyError(auth, 'Request Failed', action);
    },

    onError: (auth, action) => {
      notifyError(auth, 'Error occurred', action);
    },

    logoutAccountSuccess: (auth, action) => {
      document.location = '/login';
    },

    accountReceived: (auth, action) => {
      auth.account = action.payload[0];
      auth.ssoEnabled = action.payload[0].sso_enabled;
      auth.forceSSO = action.payload[0].force_sso;
    },

    userReceived: (auth, action) => {
      auth.user = action.payload[0];
      auth.editingUser = action.payload[0];
      auth.mfaEnabled = action.payload[0].mfa_enabled;
      if (auth.user && auth.user.accounts.length === 1) {
        setAccountID(auth.user.accounts[0].id);
      }
    },

    resetSuccess: (auth, action) => {
      document.location = '/login';
    },
    settingsToggle: (auth, action) => {
      auth.showSettings = action.payload;
    },

    changeBasicInfo: (auth, action) => {
      auth.editingUser = action.payload;
    },

    qrReceived: (auth, action) => {
      auth.qrBase64 = action.payload[0];
    },

    mfaEnableSuccess: (auth, action) => {
      auth.mfaEnabled = true;
    },

    mfaDisableSuccess: (auth, action) => {
      auth.mfaEnabled = false;
    },
    ssoEnableSuccess: (auth, action) => {
      auth.ssoEnabled = true;
    },

    ssoDisableSuccess: (auth, action) => {
      auth.ssoEnabled = false;
    },

    forceSSOSuccess: (auth, action) => {
      auth.forceSSO = true;
    },

    unforceSSOSuccess: (auth, action) => {
      auth.forceSSO = false;
    },

    resetEditingUser: (auth, action) => {
      auth.editingUser = auth.user;
    },
    ignoreOnBoardingSuccess: (auth, action) => {
      (auth.account as Account).on_boarding_active = false;
    },
  },
});

export const {
  registerSuccess,
  registerFailed,
  loginSuccess,
  loginFailed,
  requestAccountSuccess,
  requestAccountFailed,
  logoutAccountSuccess,
  accountReceived,
  userReceived,
  settingsToggle,
  resetSuccess,
  changeBasicInfo,
  qrReceived,
  mfaEnableSuccess,
  mfaDisableSuccess,
  ssoEnableSuccess,
  ssoDisableSuccess,
  forceSSOSuccess,
  unforceSSOSuccess,
  onError,
  resetEditingUser,
  ignoreOnBoardingSuccess,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const registerAccount = (user: User) =>
  apiCallBegan({
    url: '/api/v1/auth/users/',
    method: 'post',
    data: user,
    onSuccess: registerSuccess.type,
    onError: registerFailed.type,
  });

export const requestAccount = (user: User) =>
  apiCallBegan({
    url: '/api/v1/request-account/',
    method: 'post',
    data: user,
    onSuccess: requestAccountSuccess.type,
    onError: requestAccountFailed.type,
  });

export const loginAccount = (login: any) =>
  apiCallBegan({
    url: '/api/v1/auth/login/',
    method: 'post',
    data: login,
    onSuccess: loginSuccess.type,
    onError: loginFailed.type,
  });

export const logoutAccount = () =>
  apiCallBegan({
    url: '/api/v1/auth/logout/',
    method: 'post',
    onSuccess: logoutAccountSuccess.type,
    onError: loginFailed.type,
  });

export const loadUser = () =>
  apiCallBegan({
    url: '/api/v1/auth/users/me/',
    onSuccess: userReceived.type,
  });

export const loadAccount = () =>
  apiCallBegan({
    url: '/api/v1/auth/accounts/current/',
    onSuccess: accountReceived.type,
  });

export const ignoreOnBoarding = () =>
  apiCallBegan({
    url: '/api/v1/auth/accounts/ignore-onboarding/',
    onSuccess: ignoreOnBoardingSuccess.type,
    method: 'post',
  });

function notifyError(auth: AuthState, base: string, action: { payload: any; type?: string }) {
  let msg = action.payload[0];
  const resp = action.payload[1];

  // get more specific error message
  if (resp && resp.data) {
    if (typeof resp.data === 'string' || resp.data instanceof String) {
      msg = resp.data;
    } else {
      msg = resp.data[Object.keys(resp.data)[0]];
    }
  }

  auth.error = `${msg}`;
}

export const resetPassword = (d: any) =>
  apiCallBegan({
    url: '/api/v1/reset-password/',
    method: 'post',
    data: d,
    onSuccess: resetSuccess.type,
    onError: onError.type,
  });

export const inviteToResetPassword = (d: { email: any }) =>
  apiCallBegan({
    url: '/api/v1/invite-reset-password/',
    method: 'post',
    data: d,
    onError: onError.type,
  });

export const mfaEnable = (code: string) =>
  apiCallBegan({
    url: '/api/v1/mfa-enable/',
    method: 'post',
    data: { code: code },
    onSuccess: mfaEnableSuccess.type,
    onError: onError.type,
  });

export const disableMFA = () =>
  apiCallBegan({
    url: '/api/v1/mfa-disable/',
    method: 'post',
    onSuccess: mfaDisableSuccess.type,
    onError: onError.type,
  });

export const loadQRBase64 = () =>
  apiCallBegan({
    url: '/api/v1/auth/mfa-qrcode/',
    onSuccess: qrReceived.type,
    onError: onError.type,
  });

export const enableSSO = (metadata: string) =>
  apiCallBegan({
    url: '/api/v1/sso-enable/',
    method: 'post',
    data: { saml_metadata: metadata },
    onSuccess: ssoEnableSuccess.type,
    onError: onError.type,
  });

export const disableSSO = () =>
  apiCallBegan({
    url: '/api/v1/sso-disable/',
    method: 'post',
    onSuccess: ssoDisableSuccess.type,
    onError: onError.type,
  });

export const forceSSO = () =>
  apiCallBegan({
    url: '/api/v1/force-sso/',
    method: 'post',
    onSuccess: forceSSOSuccess.type,
    onError: onError.type,
  });

export const unforceSSO = () =>
  apiCallBegan({
    url: '/api/v1/unforce-sso/',
    method: 'post',
    onSuccess: unforceSSOSuccess.type,
    onError: onError.type,
  });
