export type DurationUnits = 's' | 'm' | 'h' | 'd';

export function durationDisplayName(d: DurationUnits): string {
  switch (d) {
    case 'd':
      return 'Days';
    case 'h':
      return 'Hours';
    case 'm':
      return 'Minutes';
    case 's':
      return 'Seconds';
  }
  return '';
}
